import { SchemaType } from '~/utils/conform/conform-helper.ts';

import { z } from 'zod';

export const emailLoginSchema = z.object({
  intent: z.literal('emailLogin'),
  email: z.string().email(),
});
export type EmailLoginSchema = SchemaType<typeof emailLoginSchema>;

export const socialLoginSchema = z.object({
  intent: z.literal('socialLogin'),
  provider: z.enum(['tk-auth']),
});
export type SocialLoginSchema = SchemaType<typeof socialLoginSchema>;

export const twoFactorAuthSchema = z.object({
  code: z.string().regex(/^\d{6}$/),
});

export type TwoFactorAuthSchema = SchemaType<typeof twoFactorAuthSchema>;